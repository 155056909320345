// colors
$primary: #1d1dff;
$dive-1: #1d1dff;
$dive-2: #1d1dff;
$dive-3: #1d1dff;
$dive-4: #1d1dff;
$white: #fff;
$black: #000;
$grey: #f2f2f2;
$grey-light: #f2f2f2;
$grey-regular: #aaaaaa;
$orange: #FF8730;


// layout
$min-desktosize: 1024px;
$max-mobile-size: $min-desktosize - 1px;
$container-padding-top: 2.5rem;

// grid
$gridContainerPaddingMobile: 2rem;
$gridContainerPaddingDesktop: 32px;
$gridColumnGap: 3.33%;
$gridContainerMaxWidth: 1600px + ($gridContainerPaddingDesktop * 2);

// needed for font Calculator
$min-mobile-size: 375;
$max-desktosize: 1600;

// fonts
$font-family-default: Tahoma, Arial, Helvetica Neue, Helvetica, sans-serif;
$font-family-plex-mono: 'IBM-Plex-Mono', Tahoma, sans-serif;
$font-family-plex-sans: 'IBM-Plex-Sans', Tahoma, sans-serif;

$font-global: $font-family-default;
$font-headline: $font-family-plex-mono;
$font-input: $font-family-plex-sans;

$line-height-default: 1.16rem;

$font-size-desktodefault: 16px;
$font-size-mobile-default: 13px;

// counter font
$counter-font-size: 12px;
$counter-font-family: $font-family-plex-mono;

// text, font fluid in PX
$font-size-text-desktolarge: 17;
$line-height-text-desktolarge: 27;

$font-size-text-mobile-large: 16;
$line-height-text-mobile-large: 25;

$margin-text: 2rem;

// headline, font fluid in PX
$font-size-headline-default-mobile: 36;
$line-height-headline-default-mobile: 46;
$font-size-headline-default-desktop: 50;
$line-height-headline-default-desktop: 65;

$font-size-headline-size-s-desktop: 40;
$line-height-headline-size-s-desktop: 52;
$font-size-headline-size-s-mobile: 30;
$line-height-headline-size-s-mobile: 39;

$font-size-headline-h3-desktop: 14;
$line-height-headline-h3-desktop: 22;
$font-size-headline-h3-mobile: 13;
$line-height-headline-h3-mobile: 17;

$font-size-headline-h4-desktop: 26;
$line-height-headline-h4-desktop: 26;
$font-size-headline-h4-mobile: 24;
$line-height-headline-h4-mobile: 24;

$font-size-headline-h5-desktop: 14;
$line-height-headline-h5-desktop: 22;
$font-size-headline-h5-mobile: 13;
$line-height-headline-h5-mobile: 20;

$font-size-tagline-desktop: 14;
$line-height-tagline-desktop: 22;
$font-size-tagline-mobile: 13;
$line-height-tagline-mobile: 19;

$margin-headline: 3rem;

// input
$font-size-input-field: 17px;

// radio
$radio-size: 20px;
$radio-size-inner: $radio-size - 8px;
$radio-label-size-desktop: $font-size-desktodefault;
$radio-label-size-mobile: $font-size-mobile-default;

// mod
$mod-space: 20px;

// buttons
$button-font-size: 14px;
$button-font-size-small: 16px;
$button-font-size-xs: 13px;
$button-link-font-size-mobile: 14px;
$button-link-font-size-desktop: 14px;
$button-border-radius: 50px;
$button-font-weight: 700;
$margin-button: 4rem;

// icon-button
$icon-button-size: 20px;
$icon-button-size-small: 14px;

// input
$input-height: 50px;

// switch
$switch-size: 30px;
$switch-border-radius: 0;
$switch-circle-color: $white;
$switch-inactive-color: $grey-regular;
$switch-label-tooffset: 7px;

// icon-tile
$icon-tile-space-between: $mod-space;
$icon-tile-background-color: $grey-light;
$icon-tile-background-color-hover: $grey-regular;
$icon-tile-border-color-active: $primary;
$icon-tile-border-width: 2px;
$icon-tile-border-radius: 6px;
$icon-tile-font-size-desktop: 15px;
$icon-tile-font-size-mobile: 14px;

// stedisplay
$stedisplay-icon-size-outer: 28px;
$stedisplay-icon-size-inner: 20px;
$stedisplay-spacer-line-size: 36px;
$stedisplay-spacer-line-size-space: 2px;

//tooltip
$tooltip-font-weight: 700;


