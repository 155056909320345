@use './../../styles/variables' as *;
@use './../../styles/mixin' as *;

.tooltip-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-top: 2rem;
  margin-bottom: -1rem;

  .tooltip-label {
    font-family: $font-family-plex-mono;
    font-size: $font-size-mobile-default;
    font-weight: $tooltip-font-weight;
  }
  img{
    background-color: transparent;
    border-radius: 999px;
  }
  &:hover img {
    background-color: #F2F2F2;
  }
}

.tooltip-box {
  position: absolute;
  max-width: 304px;
  word-wrap: break-word;
  word-break: break-word;
  border-radius: 16px;
  padding: 16px 24px;
  bottom: calc(100% + 8px);
  left: -24px;
  background: #F2F2F2;
  color: #000000;
  border: none;
  width: 304px;
  font-family: $font-family-plex-sans;
  font-size: 12px !important;
  line-height: 15.6px !important;
  pointer-events: none;

  @include font-fluid(
                  $font-size-text-mobile-large,
                  $line-height-text-mobile-large,
                  $font-size-text-desktolarge,
                  $line-height-text-desktolarge,
                  $line-height-tagline-mobile
  );
}


