.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}


// page transitions

.slide-horizontal-enter-active, .slide-horizontal-leave-active {
  transition: all .5s ease-in-out;
}

.slide-horizontal-enter {
  transform: translateX(100vw);
  opacity: 1;
}

.slide-horizontal-leave-to {
  transform: translateX(-100vw);
  opacity: 0;
}


.slide-vertical-enter-active, .slide-vertical-leave-active {
  transition: all .5s ease-in-out;
}

.slide-vertical-enter {
  transform: translateY(100vh);
  opacity: 1;
}

.slide-vertical-leave-to {
  transform: translateY(-100vh);
  opacity: 0;
}
