@use './../../styles/variables' as *;

.button {
  border-radius: $button-border-radius;
  cursor: pointer;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  text-transform: uppercase;
  padding: 1rem 2rem;
  min-width: 150px;
  line-height: 20px;
  letter-spacing: .2em;
  display: inline-block;
  transition: all 0.5s ease;
  font-family: $font-family-plex-mono;
  outline: 0;
  position: relative;
  text-decoration: none;
  margin-top: $margin-button;
  vertical-align: bottom;

  @media (min-width: $min-desktosize) {
    line-height: 18px;
    min-width: 200px;
    padding: 1rem 3rem;
  }

  &:focus {
    outline: 0;
  }

  &--primary, &--submit {
    border: 2px solid $primary;
    background-color: transparent;
    color: $black;

    &:not(.button--disabled):not(.button--no-hover):hover {

      background-color: $primary;
      color: $white;
    }
  }

  &--fillup {
    border: 2px solid $primary;
    background-color: transparent;
    color: $black;

    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMDAgMjIyLjM0Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzFkMWRmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkViZW5lXzIiIGRhdGEtbmFtZT0iRWJlbmUgMiI+PGcgaWQ9IkViZW5lXzEtMiIgZGF0YS1uYW1lPSJFYmVuZSAxIj48ZyBpZD0iRWJlbmVfMi0yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTItMiIgZGF0YS1uYW1lPSJFYmVuZSAxLTIiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMjIyLjM0VjkyLjY2UzUuNjEsNjEuMTMsNzAuNjEsNjMuMzhjNTQuNzUsMS45MSw1My43NC0yMS43Miw4Mi44NS0yNy42NSwyNS4wNy01LjExLDY5Ljc2LDExLjM5LDkxLjY4LDlDMjczLjI4LDQxLjcxLDI5Ny4zMywyLjIsMzAwLDBWMjIyLjI3WiIvPjwvZz48L2c+PC9nPjwvZz48L3N2Zz4=");
    background-position: 0 -75%;
    background-repeat: no-repeat;
    background-size: 101%;
    transition: background-position .4s, color .4s;
    transition-timing-function: cubic-bezier(0.44, 0.3, 0.58, 0.79);

    @media (min-width: $min-desktosize) {
      background-position: 0 -50%;
    }

    &:not(.button--disabled):not(.button--no-hover):hover {
      background-position: 100% 60%;
      color: $white;

      @media (min-width: $min-desktosize) {
        background-position: 100% 50%;
      }
    }
  }

  &--secondary {
    border: 2px solid transparent;
    background-color: transparent;
    padding: 1rem 0;
    min-width: unset;
    margin-right: 2.5rem;
    color: $black;

    @media (min-width: $min-desktosize) {
      margin-right: 4rem;
    }

    .i-icon {
      .bg-fill {
        fill: $black;
      }
    }

    &:not(.button--disabled):not(.button--no-hover):hover {
      border: 2px solid transparent;
      background-color: transparent;
      color: $primary;

      .i-icon {
        .bg-fill {
          fill: $white;
        }
      }

    }

    &.button--disabled {
      border: 2px solid transparent;
    }

  }

  &--link {
    font-family: $font-family-default;
    font-weight: bold;
    border: none;
    background-color: transparent;
    color: $black;
    padding: 0;

    font-size: $button-link-font-size-mobile;

    @media screen and (min-width: $min-desktosize) {
      font-size: $button-link-font-size-desktop;
      line-height: 1.28;
    }

    .i-icon {
      .bg-fill {
        fill: $grey-regular;
      }
    }

    &:not(.button--disabled):not(.button--no-hover):hover {

      color: $primary;

      .i-icon {
        .bg-fill {
          fill: $primary;
        }
      }

    }

  }
  &--action{
    border: 2px solid transparent;
    background-color: transparent;
    padding: 1rem 0;
    min-width: unset;
    margin-right: 2.5rem;
    color: $black;
    margin-top: 2rem;

    @media (min-width: $min-desktosize) {
      margin-right: 4rem;
    }

    .i-icon {
      .bg-fill {
        fill: $black;
      }
    }

    &:not(.button--disabled):not(.button--no-hover):hover {
      border: 2px solid transparent;
      background-color: transparent;
      color: $primary;

      .i-icon {
        .bg-fill {
          fill: $white;
        }
      }

    }

    &.button--disabled {
      border: 2px solid transparent;
    }
  }
  &--disabled {
    cursor: not-allowed;
    border: 2px solid $grey-regular;
    background-color: $white;
    color: $grey-regular;
  }

  .button__text {
    display: inline-block;
    overflow: hidden;
    line-height: 18px;
    vertical-align: bottom;
    white-space: nowrap;
  }

  .button__icon {
    opacity: 0;
    position: absolute;
    vertical-align: middle;
    left: 50%;
    top: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);

    svg {
      vertical-align: middle;
    }
  }
}

.m-button-group {
  margin-top: $mod-space;
  margin-bottom: $mod-space;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &:not(.m-button-grou-spaced) {

    .button {
      flex-grow: 1;
    }
  }

  &--spaced {
    justify-content: space-between;
  }
}
.button--no-margin {
  margin: 0;
}

.button--submitted {
  color: white;
  padding: 1rem;
  overflow: hidden;
  background: $primary;
  will-change: min-width;
  animation: submitted-button .7s ease 1 forwards;

  .button__text {
    animation: submitted-text .7s ease 1 forwards;
  }

  .button__icon {
    animation: submitted-icon .3s ease .5s 1 forwards;
  }
}


@keyframes submitted-text {
  0% {
    opacity: 1;
  }
  75% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(-20px);
    opacity: 0;
  }
}

@keyframes submitted-icon {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  75% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes submitted-button {
  from {
    min-width: 150px;
  }
  to {
    min-width: 0;
    width: calc(20px + 2px + 2rem); // lineheight + border + padding
    height: calc(20px + 2px + 2rem);
  }
}
