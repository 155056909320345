@font-face {
  font-family: 'IBM-Plex-Mono';
  src: url('./../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'IBM-Plex-Mono';
  src: url('./../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'IBM-Plex-Sans';
  src: url('./../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'IBM-Plex-Sans';
  src: url('./../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'IBM-Plex-Sans';
  src: url('./../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'IBM-Plex-Sans';
  src: url('./../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'IBM-Plex-Sans';
  src: url('./../assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
