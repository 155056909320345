@use '../../../shared-kernel/styles/variables' as *;

.user-menu {
  margin-left: auto;
  background-color: $grey-light;
  border-radius: 40px;
  padding: .5rem;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: width .3s ease-in-out, padding .3s ease-in-out;

  &--no-padding {
    @extend .user-menu;
    padding: 0;
  }

  &--timer-mode {
    @media (max-width: 1024px) {
      background-color: transparent;
    }
  }

  @media(min-width: 1024px) {
    max-height: initial;
    border-radius: 20px;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
  }
}

.user-menu__icon {
  width: 40px;
  height: 40px;
  order: 2;

  @media(min-width: 1024px) {
    order: initial;
  }
}

.user-menu__user-name {
  font-family: $font-family-plex-mono;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: $black;
  color: $primary;
  width: fit-content;
  margin-right: 0;
  max-width: 0;
  overflow: hidden;
  transition: max-width .3s ease-in-out, margin .3s ease-in-out;
  letter-spacing: 0.2em;

  @media(max-width: $max-mobile-size) {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media(min-width: $min-desktosize) {
    text-align: center;
  }

  &.user-name--active {
    max-width: 13ex; // - 3 = number of charackters because of additional ... dots
    margin-right: 1.5rem;
    margin-left: 2.5rem;

    @media(min-width: $min-desktosize) {
      margin: .5rem 0;
      margin-bottom: 0;
      max-width: 100%;
    }
  }


  @media(min-width: 1024px) {
    margin: .5rem 0;
    max-width: 100%;
  }
}

.user-menu__timer-wrapper {
  margin-top: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media (min-width: 1024px) {
    margin-top: 1rem;
    flex-direction: column;
  }
}

.user-menu__timer-label {
  margin-right: 1rem;
  text-align: right;
  font-family: $font-family-plex-mono;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;

  @media(min-width: 1024px) {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin-right: 0;
    margin-top: .5rem;
  }
}
