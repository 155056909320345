@use "./../../../shared-kernel/styles/variables" as *;

.side-nav-container {
  position: relative;
  //overflow: hidden;
  z-index: 99;
  min-height: 500px;
  display: none;
  user-select: none;
  top: 15px;

  @media screen and (min-width: $min-desktosize) {
    display: block;
    white-space: nowrap;
  }
}

.side-nav {
  background-color: rgba(225, 225, 225, .8);
  backdrofilter: blur(3px);
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  height: 60px;
  left: calc(-406px / 2);
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);;
}

.side-nav__list {
  height: 60px;
  position: relative;
  padding: 0;
}

.side-nav__list-item {
  position: relative;
  display: inline-block;
  border-radius: 50px;
  padding: 1rem 2.5rem;
  height: 60px;
  z-index: 1;

  font-family: $font-family-plex-mono;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: .2em;
  cursor: pointer;
  text-transform: uppercase;

  color: transparent;
  background: linear-gradient(to right, $primary, $primary 50%, $black 50%);
  background-size: 200% 100%;
  background-clip: text;
  background-position: 100%;
  transition: background-position .5s ease, color .5s;


  &:hover:not(.side-nav__list-item--locked):not(.side-nav__list-item--active) {
    background-position: 0 100%;

    .fraction__character,
    .fraction__character.fraction__character--top {
      border-color: $primary;
      background: linear-gradient(to right, $primary, $primary 50%, $black 50%);
      background-position: 0 100%;
      color: transparent;
      background-size: 200% 100%;
      background-clip: text;
      transition: background-position .5s ease, color .5s;
    }
  }

  &--active {
    @extend .side-nav__list-item;
    color: $white;
    transition: color .8s;
  }

  &--locked {
    @extend .side-nav__list-item;
    color: $grey-regular;
    background: unset;
    transition: color .8s;
  }
}

.side-nav_indicator {
  display: none;

  &--active {
    display: block;
    position: absolute;
    height: 100%;
    min-width: 50px;
    padding: 0 30px;
    bottom: 0;
    z-index: 0;
    border-radius: 50px;
    background-color: $primary;

    transition: left .5s, width .5s;
    transition-timing-function: cubic-bezier(0.84, 0.3, 0.58, 0.79);
  }
}
