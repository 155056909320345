@use './../../styles/variables' as *;
@use './../../styles/mixin' as *;

.tagline {
  font-family: $font-family-plex-mono;
  font-weight: 500;
  display: inline-block;

  @include font-fluid(
                  $font-size-tagline-mobile,
                  $line-height-tagline-mobile,
                  $font-size-tagline-desktop,
                  $line-height-tagline-desktop
  );

  &--block {
    display: block;
    width: 50%;
  }

  &--margin {
    margin-bottom: 1.5rem;
    min-width: 245px;
  }

  &--margin-top {
    margin-top: 1rem;
  }
}
