@use './../../styles/variables' as *;
@use './../../styles/mixin' as *;

.text {
  font-family: $font-family-plex-sans;
  margin-top: $margin-headline;
  white-space: pre-line;

  &--italic, & i {
    font-style: italic;
  }

  &--bold, & strong {
    font-weight: 600;
  }

  @include font-fluid(
                  $font-size-text-mobile-large,
                  $line-height-text-mobile-large,
                  $font-size-text-desktolarge,
                  $line-height-text-desktolarge
  );

  a {

    color: $primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
