@use './../../../shared-kernel/styles/variables' as *;

.dialog {
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $grey-light;
  border-radius: 20px;
  padding: 1.5rem;
  @media (min-width: $min-desktosize) {
    padding: 2rem;
  }

  .headline {
    margin: 0;
  }

  &::backdrop {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.dialog__button-wrapper {
  display: flex;
  margin-top: 128px;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

  .button--secondary {
    @media screen and (max-width: 480px) {
      margin-right: initial;
    }
  }

  * {
    margin: 0;
  }

  .button--fillup {
    display: block;
    align-self: flex-end;
    margin-left: auto;

    @media screen and (max-width: 480px) {
      margin-left: initial;
      align-self: center;
    }
  }
}

