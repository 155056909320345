@use '../../../shared-kernel/styles/variables' as *;

#timer {
  width: 50px;
  position: relative;

  @media (min-width: 1023px) {
    width: 90px;
  }

  &.active {
    margin-bottom: .5rem;

    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
  }
}

.timer__background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  circle {
    fill: $grey-light
  }
}

.timer__label {
  color: $primary !important;
  font-family: $font-family-plex-sans;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;

  @media (min-width: 1023px) {
    font-size: 19px;
    font-weight: 400;
  }
}
