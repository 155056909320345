@use "./../../styles/variables" as *;
@use "./../../styles/mixin" as *;

.card {
  background-color: $grey-light;
  border-radius: 20px;
  padding: 26px 32px 36px 32px;
}

.card__header {
  display: flex;
  align-items: center;
}

.card__header-icon {
  height: 40px;
  margin-right: 1.5rem;
}

.card__header--clickable {
  cursor: pointer;
}

.card__button-wrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  * {
    margin-top: 0;
  }
}

.card__column-wrapper {
  width: 100%;
  height: 29.25rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 32px;
  padding-right: 3.188rem;
}

.card__column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 34px;
  button {
    width: fit-content;
  }
}

.card__body-text {
  font-family: $font-family-plex-sans;
  font-style: italic;
  word-break: break-word;
  margin-top: 1rem;
  hyphens: auto;

  @include font-fluid(
                  $font-size-text-mobile-large,
                  $line-height-text-mobile-large,
                  $font-size-text-desktolarge,
                  $line-height-text-desktolarge
  );
}

.card__info {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.card__translation-indicator {
  font-size: 12px;
}


