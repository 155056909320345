@use './../../styles/variables' as *;

.input,
input.input {
  position: relative;
  border: 1px solid transparent;
  line-height: 0;

  &--growing {
    overflow: hidden;
    padding-bottom: 15px;
    transition: height 0.5s;
    margin-top: 65px;


    &-filled {
      .input__field {
        color: $black;
      }

      .input__progress {
        background: $black;
      }

    }

    .input__field {
      padding-bottom: inherit;
    }
  }

  &__field {
    width: 100%;
    margin-top: -15px;
    padding: 15px 10px 15px 0 !important;
    border: 0 solid transparent;
    border-radius: 0px !important;
    border-bottom: solid 2px $grey-regular;
    border-bottom: solid 2px $grey-regular;

    background-color: $white;
    font-size: $font-size-input-field !important;
    font-family: $font-input;
    color: $primary;
    font-style: italic;

    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    height: $input-height;

    &.error {
      border-bottom: solid 2px $orange;
    }
  }

  &__placeholder {
    display: inline-block;
    font-style: italic;
    position: absolute;
    left: 10px;
    bottom: 10px;
    opacity: 0.5;
  }

  &__textarea {
    margin: 0 auto;
    resize: none;
    overflow-y: hidden;
  }

  &__progress {
    height: 2px;
    background: $primary;
    position: absolute;
    bottom: 15px;
    left: 1px;

    transition: width 0.2s;
  }

  &__counter {
    position: absolute;
    bottom: 5px;;
    right: 5px;
    color: $grey-regular;
    font-size: $counter-font-size;
    font-family: $counter-font-family;
  }

}

.input__field--error {
  .input__counter {
    visibility: hidden;
  }
}

.input {
  p.errorText {
    margin-top: 10px;
    text-align: right;
    color: $orange;
    font-size: $counter-font-size;
    font-family: $counter-font-family;
    font-weight: 700;
  }
}

/* Place Holder CSS */
::-webkit-input-placeholder {
  color: $black;
}

:-moz-placeholder {
  color: $black;
}

::-moz-placeholder {
  color: $black;
}
