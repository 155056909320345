@use '../../../../shared-kernel/styles/variables' as *;
@use '../../../../shared-kernel/styles/mixin' as *;

.statistic-card {
  padding: 32px;

  h2 {
    margin: 0;
  }
}

.statistic-card__wrapper {
  margin-top: 16px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
  grid-column: 2;
}

.value-card {
  background-color: $white !important;
  display: flex;
  flex-direction: column;

  :first-child {
    align-self: flex-end;
  }
}

.value-card__icon {
  width: 65px;
  height: 62.646px;
}

.value-card__value {
  font-family: $font-family-plex-sans;
  font-weight: 600;
  margin-top: 0.938rem;
  @include font-fluid(
                  $font-size-headline-size-s-mobile,
                  $line-height-text-mobile-large,
                  $font-size-headline-size-s-desktop,
                  $line-height-text-desktolarge
  );
}

.text {
  margin-top: 0.5rem !important;
}
