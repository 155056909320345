@use '../../../../shared-kernel/styles/variables' as *;
@use '../../../../shared-kernel/styles/mixin' as *;

.more-answer-card {
  .card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .headline {
    margin:5px 0 0 0 ;
  }

  &__highlighted {
    background-color: $primary;
    color: $white;

    .headline {
      color: $white;
    }

    .more-answer-card__icon {

      .more-answer-card__icon--background {
        fill: $white;
        opacity: .6;
      }

      .more-answer-card__icon--arrow {
        stroke: $primary;
        opacity: .6;
      }

      &:hover {
        .more-answer-card__icon--background {
          fill: $white;
          opacity: 1;
        }

        .more-answer-card__icon--arrow {
          fill: $white;
          opacity: 1;
        }
      }
    }
  }
}

.more-answer-card__pages {
  font-weight: 700;

  @include font-fluid(
          $font-size-tagline-desktop,
          $line-height-tagline-desktop,
          $font-size-tagline-mobile,
          $line-height-tagline-mobile,
  );
}

.more-answer-card__icon-wrapper {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
}

.more-answer-card__icon {
  width: 40px;
  height: 40px;
  cursor: pointer;

  .more-answer-card__icon--background {
    fill: darkgray;
  }

  .more-answer-card__icon--arrow {
    stroke: $white;
  }

  &:hover {
    .more-answer-card__icon--background {
      fill: $primary;
    }

    .more-answer-card__icon--arrow {
      fill: $primary;
    }
  }
}
