@use "../../../shared-kernel/styles/variables" as *;

.header {
  margin-bottom: 60px;
  position: absolute;
  top: $container-padding-top;
  left: 0;
  right: 0;

  @media screen and (max-width: $max-mobile-size) {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.header__brand {
  height: 60px;
  display: block;
  margin: auto;
}
