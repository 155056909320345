@use "../../../shared-kernel/styles/variables" as *;

.fraction {
  position: relative;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.001em;
}

.fraction__character {
  padding: 0 .5em;
  display: block;
  font-size: .75em;
  line-height: 1.25em;
  transition: color .8s, border-color .8s;
}

.side-nav__list-item .fraction__character {
  color: $black;
}

.side-nav__list-item--active .fraction__character {
  color: $white;
}

.side-nav__list-item--locked .fraction__character {
  color: $grey-regular;
}

.side-nav__list-item--locked {
  cursor: initial;
}

.fraction__character--top {
  border-bottom: 1px solid
}

.side-nav__list-item .fraction__character--top {
  border-color: $black;
}

.side-nav__list-item--active .fraction__character--top {
  border-color: $white;
}

.side-nav__list-item--locked .fraction__character--top {
  border-color: $grey-regular;
}
