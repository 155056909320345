@use "../../../../shared-kernel/styles/variables" as *;

.tab-nav {
  margin-bottom: 3.5rem;
  padding-left: 0;
  width: calc(100% + 1.5rem);
  max-width: calc(100% + 1.5rem);
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  user-select: none;
  -ms-overflow-style: none; // hide scrollbar for IE/Edge
  scrollbar-width: none; // hide scrollbar for Firefix
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome Safari Opera
  }

  margin-right: -1.5rem;
  position: sticky;
  top: 0;

  @media (min-width: 1024px) {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.tab-nav__item {
  display: inline-block;
  font-family: $font-family-plex-mono;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 11px;
  letter-spacing: .2em;
  padding: .5rem 0;
  cursor: not-allowed;
  color: $grey-regular;

  cursor: pointer;
  color: transparent;
  background: linear-gradient(to right, $primary, $primary 50%, $black 50%);
  background-size: 200% 100%;
  background-clip: text;
  background-position: 100%;
  transition: background-position .5s ease, color .5s;

  &:hover:not(.tab-nav__item--disabled) {
    background-position: 0 100%;
  }

  @media (min-width: 1024px) {
    font-size: 13px;
    line-height: 13px;
  }

  & + .tab-nav__item {
    margin-left: 2rem;
  }

  &--active {
    @extend .tab-nav__item;
    cursor: pointer;
    color: $primary;

    & > span {
      cursor: default;
    }
  }

  &--disabled {
    @extend .tab-nav__item;
    color: $grey-regular;
    background: transparent;
    pointer-events: none;
  }
}

.tab-nav__icon--forward {
  rotate: 270deg;
}

.tab-nav__icon--back {
  rotate: 90deg;
}
