@use '../../../../shared-kernel/styles/variables' as *;
@use '../../../../shared-kernel/styles/mixin' as *;

.own-card {
  padding: 26px 32px;

  .own-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.own-answer-card__toggle__icon {
  float: right;
}

.card__toggle__icon--active {
  transform: rotate(180deg);
}
