@use '../../../../shared-kernel/styles/variables' as *;

.language-selection {
  .button {
    margin-top: 132px;
  }

  .card {
    padding: 32px;

    .card__body-text {
      margin-top: 0;
    }
  }

  .radio {
    margin: 32px 0 0 0;
  }
}

.card--selected {
  .card__body-text {
    color: $primary
  }
}
