// Setup
@use 'shared-kernel/styles/fonts' as  *;
@use 'shared-kernel/styles/variables' as *;
@use 'shared-kernel/styles/mixin' as *;
@use 'shared-kernel/styles/transitions' as *;

body {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }

  min-width: 360px;
  min-height: 100dvh;
  max-height: 100dvh;
  padding: 2.5rem 0;
  margin: auto;
  font-family: $font-family-default;
  font-size: $font-size-desktodefault;
  line-height: $line-height-default;
  font-weight: 300;
  color: $black;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #1d1dff #c2c2fa;
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  background-color: $grey-regular;
  width: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  width: 2px;
  border: 0px none transparent;
}

.flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &--end {
    margin-top: auto;
  }
}

.resized-img {
  max-width: 100%;
}

//needed for device-detection - start
.show-on-mobile {
  display: block;
}

.hide-on-desktop {
  display: none;
}

@media screen and (min-width: $min-desktosize) {
  .show-on-mobile {
    display: none;
  }
  .hide-on-desktop {
    display: block;
  }
}

//needed for device-detection - end

.grid-container {
  padding-left: $gridContainerPaddingMobile;
  padding-right: $gridContainerPaddingMobile;

  @media screen and (min-width: $min-desktosize) {

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $gridColumnGap;
    padding-left: $gridContainerPaddingDesktop;
    padding-right: $gridContainerPaddingDesktop;

    margin-left: auto;
    margin-right: auto;
  }

  .grid-container {
    padding: 0;
  }
}

.header ~ .m-grid {
  margin-top: 96px;

  @media (min-width: $min-desktosize) {
    margin-top: 96px;
  }
}

.m-grid--no-overflow {
  overflow-x: hidden;
}
