@use "./variables" as *;

// Mixin font fluid calculator
@mixin font-fluid(
  $min-font-size,
  $min-line-height,
  $max-font-size,
  $max-line-height,
  $min-screen-size: $min-mobile-size,
  $max-screen-size: $max-desktosize
) {
  $min-font-size-px: $min-font-size + 'px';
  $min-line-height-px: $min-line-height + 'px';
  $min-screen-size-px: $min-screen-size + 'px';

  font-size: calc(
          #{$min-font-size-px} + (#{$max-font-size} - #{$min-font-size}) * (100vw - #{$min-screen-size-px}) / (#{$max-screen-size} - #{$min-screen-size})
  );

  line-height: calc(
          #{$min-line-height-px} + (#{$max-line-height} - #{$min-line-height}) * ((100vw - 375px) /
          (1920 - 375))
  );
}

// Mixin Grid
@for $i from 2 through 3 {
  .column-#{14-$i*2}-centered {
    grid-column: #{$i}/#{14-$i};
  }
}

@for $i from 1 through 12 {
  .column-#{$i} {
    grid-column: span ($i);
  }
}

// grid-column: key(startColumn)/value(endColumn);
$offsetComunMap: (
        11: (
                13,
        ),
        3: (
                10,
                11,
                12
        )
);

@each $offset, $columns in $offsetComunMap {
  @each $column in $columns {
    .offset-#{$offset}-column-#{$column} {
      grid-column: #{$offset}/#{$column};
    }
  }
}

@for $i from 1 through 12 {
  .row-#{$i} {
    grid-row: $i;
  }
}
