@import './../../styles/variables';

.radio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;

  &__input {
    display: none;
  }

  &__label {

    display: inline-block;
    color: $black;
    position: relative;
    padding-left: $radio-size + 10px;
    cursor: pointer;

    font-size: $radio-label-size-mobile;

    @media screen and (min-width: $min-desktosize) {
      font-size: $radio-label-size-desktop;
    }

    &:after {

      content: '';
      width: $radio-size-inner;
      height: $radio-size-inner;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: ($radio-size - $radio-size-inner) / 2;
      left: ($radio-size - $radio-size-inner) / 2;
      transition: 0.15s;
      transform: scale(0);

      margin-top: -(($radio-size - $radio-label-size-mobile) / 2);

      @media screen and (min-width: $min-desktosize) {
        margin-top: -(($radio-size - $radio-label-size-desktop) / 2);
      }

    }

    &:before {

      content: '';
      width: $radio-size;
      height: $radio-size;
      border: 2px solid $primary;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      transition: 0.15s;

      margin-top: -(($radio-size - $radio-label-size-mobile) / 2);

      @media screen and (min-width: $min-desktosize) {
        margin-top: -(($radio-size - $radio-label-size-desktop) / 2);
      }

    }

    &--active {

      &:after {
        background-color: $primary;
        transform: scale(1);
      }

    }

  }

}
