@use '../../../../shared-kernel/styles/variables' as *;

.feedback-card {
  background-color: $primary;
  color: $white;

  .input {
    margin-top: 23px;

    ::placeholder {
      color: $white;
    }
  }

  .input__field {
    background-color: $primary;
    color: $white;
    border-bottom-color: $white;
  }

  .input__counter {
    font-weight: 600;
    color: $white;
  }

  .button {
    margin-top: 16px;
    border-color: $white;
    color: $white;
  }
}
