@use './../../styles/variables' as *;
@use './../../styles/mixin' as *;

@keyframes stroke {
  from {
    width: 0;
  }

  to {
    width: 40px;
  }
}

@media screen and (min-width: $min-desktosize) {
  @keyframes stroke {
    from {
      width: 0;
    }

    to {
      width: 60px;
    }
  }
}

.headline {
  font-family: $font-headline;
  font-weight: 700;
  color: $black;
  margin-bottom: 3rem;

  @include font-fluid(
                  $font-size-headline-default-mobile,
                  $line-height-headline-default-mobile,
                  $font-size-headline-default-desktop,
                  $line-height-headline-default-desktop
  );

  &--margin-top {
    margin-top: $margin-headline;
  }

  &--no-overflow {

    @media (min-width: $min-desktosize) {
      max-width: 18ex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--border {
    margin-bottom: 2.5rem;

    &:after {
      content: '';
      height: 2px;
      display: block;
      width: 40px;
      background: $black;
      margin-top: $margin-headline;
      animation: stroke 1s ease forwards;

      @media screen and (min-width: $min-desktosize) {
        width: 60px;
      }
    }
  }

  &-wrapper {
    display: flex;
  }

  &--h2 {
    font-family: $font-headline;
    font-weight: 700;

    @include font-fluid(
                    $font-size-headline-size-s-mobile,
                    $line-height-headline-size-s-mobile,
                    $font-size-headline-size-s-desktop,
                    $line-height-headline-size-s-desktop
    );
  }

  &--h3 {
    margin-top: 0;
    font-family: $font-headline;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: $grey-regular;

    @include font-fluid(
                    $font-size-headline-h3-mobile,
                    $line-height-headline-h3-mobile,
                    $font-size-headline-h3-desktop,
                    $line-height-headline-h3-desktop
    );
  }

  &--h4 {
    margin-top: 0;
    font-family: $font-family-plex-sans;

    @include font-fluid(
                    $font-size-headline-h4-mobile,
                    $line-height-headline-h4-mobile,
                    $font-size-headline-h4-desktop,
                    $line-height-headline-h4-desktop
    );
  }

  &--h5 {
    margin-top: 0;
    font-family: $font-family-plex-sans;
    font-weight: 400;

    @include font-fluid(
                    $font-size-headline-h5-mobile,
                    $line-height-headline-h5-mobile,
                    $font-size-headline-h5-desktop,
                    $line-height-headline-h5-desktop
    );
  }
}
