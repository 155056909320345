@use "./../../../../shared-kernel/styles/variables" as *;
@use "./../../../../shared-kernel/styles/mixin" as *;

.vote-card__badge {
  width: 50px;
  height: 50px;
  border: 2px solid $primary;
  border-radius: 50px;
  position: relative;

  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMDAgMjIyLjM0Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzFkMWRmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkViZW5lXzIiIGRhdGEtbmFtZT0iRWJlbmUgMiI+PGcgaWQ9IkViZW5lXzEtMiIgZGF0YS1uYW1lPSJFYmVuZSAxIj48ZyBpZD0iRWJlbmVfMi0yIiBkYXRhLW5hbWU9IkViZW5lIDIiPjxnIGlkPSJFYmVuZV8xLTItMiIgZGF0YS1uYW1lPSJFYmVuZSAxLTIiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMjIyLjM0VjkyLjY2UzUuNjEsNjEuMTMsNzAuNjEsNjMuMzhjNTQuNzUsMS45MSw1My43NC0yMS43Miw4Mi44NS0yNy42NSwyNS4wNy01LjExLDY5Ljc2LDExLjM5LDkxLjY4LDlDMjczLjI4LDQxLjcxLDI5Ny4zMywyLjIsMzAwLDBWMjIyLjI3WiIvPjwvZz48L2c+PC9nPjwvZz48L3N2Zz4=");
  background-position: center -180%;
  background-repeat: no-repeat;
  background-size: 200%;
  transition: background-position .4s, color .4s;
  transition-timing-function: cubic-bezier(0.44, 0.3, 0.58, 0.79);

  &:hover, &.selected {
    background-position: 50% 70%;
    cursor: pointer;

    .vote-card__badge-icon {
      opacity: 1;
    }

    span {
      opacity: 1 !important;
    }
  }
}

.vote-card__badge-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .4s;
  transition-timing-function: cubic-bezier(0.44, 0.3, 0.58, 0.79);
}

.vote-card--selected {
  color: $primary;

  .vote-card__badge {
    background-position: 50% 70%;
  }

  .vote-card__badge-icon {
    opacity: 1;
  }
}
