@use './../../../shared-kernel/styles/variables' as *;

.loading-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  height: 100vh;
  right: 0;
  left: 0;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  &--transparent {
    background-color: rgba(255, 255, 255, .8);
    backdrofilter: blur(3px);
  }
}

.loading__indicator {
  border: 1.5px solid $primary;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-image: url('./../../../shared-kernel/assets/icons/wave-outline.svg');
  background-size: 40px;
  background-position-x: 40px;
  background-position-y: center;
  background-repeat: repeat-x;
  will-change: background-position;
  animation: loadingWave 1.3s infinite;
  animation-timing-function: linear;
}

@keyframes loadingWave {
  to {
    background-position-x: 0px;
  }
}
